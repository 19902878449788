.service {
    padding-top: 120px;
}

.service-content {
    padding: 30px 50px 0 50px;

    .section-title {
        margin-bottom: 40px;
    }

    p {
        font-size: 18px;
        line-height: 34px;
    }
}

@media (max-width: 991px) {
    .service {
        padding-top: 80px;
    }

    .service-content {
        padding: 0;
        margin-top: 50px;
    }
}
