.icon-box-wrapper {
	margin-bottom: 30px;

	.icon-box-icon {
		margin-bottom: 20px;
	}

	.icon-box-title {
		font-size: 20px;
		font-weight: 600;
		line-height: 30px;
		margin-bottom: 20px;
		transition: all 0.3s ease-in-out;

		a {
			color: $colorSecondary;
			&:hover {
				color: $colorPrimary;
			}
		}

		img {
			width: 80px;
			height: 80px;
			margin-right: 10px;
		}
	}

	p {
		margin: 0;
	}

	&.style-two {
		margin-top: 30px;
		margin-bottom: 30px;
		.icon-box-title {
			margin-bottom: 10px;
		}
	}

	&.style-four {
		border-radius: 4px;
		border: 1px solid #ece4e4;
		padding: 60px 40px 32px;
		transition: all 0.3s ease-in-out;
		position: relative;
		overflow: hidden;
		height: calc(100% - 30px);

		.layer {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			z-index: -1;
			transform: translateY(-270px);
			transition: all 0.6s cubic-bezier(0.4, 0, 0, 1);
		}

		.icon-box-icon {
			margin-bottom: 44px;
			width: 80px;
			height: 80px;
		}

		.icon-box-title {
			font-size: 22px;
			font-weight: 500;
			margin-bottom: 25px;
		}

		p {
			margin-bottom: 22px;
		}

		.more-btn {
			font-size: 35px;
			color: #bdb4b4;
			display: inline-block;

			&:hover {
				color: $color__theme;
			}
		}

		&:hover {
			box-shadow: 0px 40px 60px 0px rgba(79, 35, 35, 0.14);

			.layer {
				transform: translateY(0);
			}
		}
	}

	&.style-seven {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 90px;

		.icon-box-icon {
			width: 60px;
			margin-right: 20px;
		}

		.icon-box-content {
			flex: 2;

			.icon-box-title {
				font-size: 20px;
				color: #081e39;
				font-weight: 600;
				margin-bottom: 13px;

				a {
					color: #081e39;

					&:hover {
						color: $color__theme-2;
					}
				}
			}

			p {
				color: #5e5b74;
				margin-bottom: 17px;
			}

			.read-more {
				color: #5e5b74;
				font-size: 16px;
				font-weight: 500;

				&:hover {
					color: $color__theme-2;
				}
			}
		}
	}

	&.style-ten {
		padding: 50px 40px;
		border-radius: 10px;
		background-color: rgb(255, 255, 255);
		box-shadow: 0px 20px 60px 0px rgba(1, 51, 32, 0.1);
		text-align: center;
		.icon-box-title {
			margin-bottom: 15px;
		}
		p {
			margin-bottom: 30px;
		}
	}
}

@media (max-width: 991px) {
	.icon-box-wrapper.style-two {
		text-align: center;
	}

	.icon-box-wrapper {
		.icon-box-title {
			a {
				br {
					display: none;
				}
			}
		}
	}

	.icon-box-wrapper {
		&.style-seven {
			margin-bottom: 50px;
		}
	}

	.icon-box-wrapper.style-eight p {
		br {
			display: none;
		}
	}
}

@media (max-width: 768px) {
	.icon-box-wrapper p {
		br {
			display: none;
		}
	}
}