.swiper-button-prev,
.swiper-container-rtl .swiper-button-next,
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    background-image: none;
    position: absolute;
    top: 50%;
}

.testimonials {
    background: $background__bg;
    .scroll-circle {
        bottom: 35%;
        right: -133px;
    }
}

.testimonial-wrapper {
    padding: 0 100px 140px;
    position: relative;
    z-index: 22;

    .shape-shadow {
        border-radius: 10px;
        background-color: rgb(255, 255, 255);
        opacity: 0.4;
        box-shadow: 0px 30px 60px 0px rgba(102, 74, 74, 0.2);
        height: 100px;
        max-width: 54%;
        position: absolute;
        bottom: 100px;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
    }

    .swiper-button-next,
    .swiper-button-prev {
        color: #bdb4b4;
        font-size: 36px;
        transition: all 0.3s ease-in-out;
        outline: 0;
        top: 37%;
        &:hover {
            color: $color__theme;
        }
    }

    .swiper-button-next {
        right: 20px;
    }

    .swiper-button-prev {
        left: 20px;
    }
}

.testimonial-container {
    border-radius: 10px;
    max-width: 860px;
    margin: 0 auto;
    position: relative;
    box-shadow: 0px 30px 60px 0px rgba(79, 35, 35, 0.1);
    background: #fff;
    width: 100%;

    .testimonial {
        text-align: center;
        margin: 0 auto;
        padding: 60px;
        position: relative;

        .testimonial-content {
            max-width: 720px;
            margin: 0 auto 30px;
            p {
                color: #656177;
                font-size: 18px;
                line-height: 32px;
            }
        }

        .single-bio-thumb {
            width: 80px;
            margin: 0 auto 41px;
            box-shadow: 0px 20px 30px 0px rgba(79, 35, 35, 0.2);
            border-radius: 50%;
        }

        .bio-info {
            .name {
                font-size: 20px;
                line-height: 32px;
                margin: 0;
            }

        }
    }
}

@media (max-width: 1024px) {
    .testimonials {
        .scroll-circle {
            max-width: 500px;
        }
    }
}

@media (max-width: 991px) {
    .testimonials {
        padding: 76px 0 10px;
    }

    .testimonial-wrapper {
        padding: 0 50px 110px;

        .swiper-button-prev {
            left: 0;
        }

        .swiper-button-next {
            right: 10px;
        }

        .shape-shadow {
            bottom: 80px;
        }
    }

    .testimonial-container {
        .testimonial {
            .testimonial-content {
                p {
                    font-size: 18px;
                    line-height: 34px;
                }
            }
        }

    }

    .testimonials-tax {
        padding: 72px 0 80px;
    }
}

@media (max-width: 768px) {

    .testimonials {
        padding: 76px 0 30px;
    }

    .testimonial-container {
        .testimonial {
            padding: 40px;

            .testimonial-content {

                p {
                    font-size: 16px;
                    line-height: 30px;
                }
            }
        }
    }

    .testimonial-wrapper {
        padding: 0 50px 70px;

        .shape-shadow {
            bottom: 50px;
        }
    }
}

@media (max-width: 576px) {
    .testimonial-container-wrapper,
    .testimonial-wrapper {
        .swiper-button-prev,
        .swiper-button-next {
            display: none;
        }
    }

    .testimonial-wrapper {
        padding: 0 0 70px;
    }

    .testimonial-container .testimonial {
        padding: 40px 25px;
    }
}