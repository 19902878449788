.error-page {
    background: #fef9f9;
    padding: 185px 0 165px;
}

.error-content-wrapper {

    img {
        margin-bottom: 30px;
    }

    .error-title {
        font-size: 40px;
        font-weight: 600;
        line-height: 1.3;
    }

    p {
        font-size: 20px;
        margin-bottom: 47px;
    }

    .f-btn {
        padding: 11px 67px;
    }
}

@media (max-width: 991px) {
    .error-page {
        background: #fef9f9;
        padding: 130px 0 80px;
    }

    .error-content-wrapper {
        .error-title {
            font-size: 35px;
            font-weight: 600;
            line-height: 1.3;
        }

        p {
            font-size: 16px;
        }
    }
}