.section-title {
	margin-bottom: 70px;

	.sub-title {
		font-size: 16px;
		text-transform: uppercase;
		font-weight: 500;
		color: $color__theme;
		letter-spacing: 3px;
	}

	.title {
		font-size: 36px;
		font-weight: 600;
		line-height: 1.4;

		span {
			color: $color__theme
		}
	}

	.title-two {
		font-size: 30px;
		font-weight: 500;
		line-height: 44px;
		max-width: 930px;
		margin: 0 auto;

		span {
			color: $color__theme;
		}
	}

	strong {
		color: $color__theme;
		font-weight: 500;
	}

	&.dark-title {

		.sub-title,
		.title {
			color: #fff;
		}
	}
}

@media (max-width: 991px) {
	.section-title {
		margin-bottom: 50px;

		.title {
			font-size: 32px;
		}

		p {
			br {
				display: none;
			}
		}
	}

	.section-title {
		.title-two {
			font-size: 26px;
			line-height: 36px;
		}
	}
}

@media (max-width: 768px) {
	.section-title {
		.title {
			font-size: 28px;

			br {
				display: none;
			}
		}
	}
}

