body {
	font-family: $font__primary;
	font-size: 16px;
	line-height: 28px;
	color: $color__body;
	overflow-x: hidden;
}


svg {
	display: block;
}

a,
button,
input,
button,
select {
	transition: all 0.3s ease-in-out;
}

button {
	cursor: pointer;
	outline: 0;
}

input,
textarea,
select {
	width: 100%;
	padding: 10px 12px;
	outline: 0;
}


a,
a:hover {
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font__heading;
	margin-top: 0;
	font-weight: 600;
	color: $colorSecondary;
}

img {
	max-width: 100%;
	height: auto;
}

.pr {
	position: relative;
}

.pt-7 {
	padding-top: 70px;
}

.container-wrap {
	max-width: 95%;
	margin: 0 auto;
	border-radius: 20px;
	position: relative;
	overflow: hidden;
}

.mw-none {
	max-width: unset !important;
}

@media (min-width: 1200px) {
	.container {
		max-width: 1200px;
	}
}

@media (min-width: 991px) {
	.pr-85 {
		padding-right: 85px;
	}

	.pl-85 {
		padding-left: 85px;
	}
}

.section-small {
	margin-bottom: 80px;

	.title {
		font-size: 20px;
		font-weight: 500;
	}
}

.mt-40 {
	margin-top: 40px;
}

.mt-60 {
	margin-top: 60px;
}

.no-scroll {
	overflow-y: hidden !important;
}

.container-wide {
	max-width: 1700px;
	padding: 0 15px;
	margin: 0 auto;
}

strong {
	font-weight: 500;
	color: $color__theme;
}