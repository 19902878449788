input,
textarea,
select {
    background: #f9f5f7;
    padding: 15px 30px;
    margin-bottom: 30px;
    border-radius: 10px;
    border: 1px solid #f9f5f7;
    &:focus {
        background: transparent;
        border-color: #efbbd9;
    }
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;  
    // background: #2c3e50;
    background-image: none;
}

/* Remove IE arrow */
select::-ms-expand {
    display: none;
}

/* Custom Select */
.pix-select {
    position: relative;
    display: flex; 
}

select {
    flex: 1;
    color: #000;
    cursor: pointer;
    padding: 0 20px;
    outline: 0;
}

.select-wrapper {
    position: relative;
    &:after {
        content: "\f078";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        position: absolute;
        top: 16px;
        right: 25px;
        cursor: pointer;
        pointer-events: none;
        transition: .25s all ease;
        font-size: 18px;
    }
    &:hover:after {
        color: $color__theme;
    }
}