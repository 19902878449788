.site-header {
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 9999;
	transition: all 0.3s ease-in-out;

	.header-inner {
		position: relative;

		.site-logo {
			a {
				display: block;
					max-width: 130px;
				img {
					width: 100%;
				}
				.sticky-logo {
					display: none;
				}
				@media (min-width: 768px) {
					max-width: 170px;
				}
			}
		}

		.site-nav {
			display: flex;
			align-items: center;
			width: 100%;
			justify-content: space-between;

			.menu-wrapper {
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
			}

			.nav-right {
				margin-left: 20px;

				.nav-btn {
					background: #fff;
					box-shadow: 0px 20px 30px 0px rgba(79, 35, 35, 0.15);
					color: $color__theme;
					display: inline-block;
					padding: 7px 10px;
					font-weight: 600;
					border-radius: 30px;
					font-size: 14px;
					border: 2px solid transparent;

					&:hover {
						background: $color__theme;
						color: #fff;
					}
				}

				@media (min-width: 768px) {
					margin-left: 50px;

					.nav-btn {
						padding: 7px 37px;
					}
				}
			}
		}
	}

	.site-mobile-logo {
		display: none;
	}

	.site-main-menu {
		margin: 0;
		padding: 0;
		list-style: none;
		display: flex;

		li {
			margin-left: 20px;
			position: relative;
			padding: 36px 0;

			@media (min-width: 768px) {
				margin-left: 40px;
			}

			&:last-child {
				margin-right: 0;
			}

			> a {
				color: #fff;
				font-size: 15px;
				font-weight: 500;
				position: relative;

				&:after {
					content: '';
					position: absolute;
					bottom: -2px;
					width: 0;
					height: 1px;
					background: #fff;
					opacity: 1;
					transition: all 0.3s ease-in-out;
					left: 0;
				}

				&:hover,
				&.current_page {

					&:after {
						width: 100%;
						opacity: 1;
					}
				}
			}


			&.menu-item-has-children {

				&:after {
					// content: "\33";
					position: absolute;
					right: -18px;
					top: 50%;
					transform: translateY(-50%);
					font-family: eleganticons;
					font-size: 10px;
					font-weight: 700;
					color: #fff;
					font-size: 14px;
					transition: all 0.3s ease-in-out;
				}

				&:hover {
					&:after {
						color: $color__theme;
					}
				}
			}

			.sub-menu {
				display: block;
				margin: 0;
				padding: 23px 30px;
				list-style: none;
				background: #fff;
				box-shadow: 0px 0px 36px 4px rgba(79, 35, 35, 0.1);
				position: absolute;
				top: 110%;
				left: 0;
				min-width: 250px;
				visibility: hidden;
				opacity: 0;
				transition: all 0.3s ease-in-out;
				z-index: 999999;
				border-radius: 4px;

				li {
					display: block;
					margin: 0;
					padding: 0;

					&.menu-item-has-children {

						&:after {
							content: "\35";
							right: 0px;
							color: #333;
						}

						.sub-menu {
							left: 105%;
							top: 0;
							visibility: hidden;
							opacity: 0;
						}

						&:hover {
							.sub-menu {
								top: -23px;
								visibility: visible;
								opacity: 1;
							}
						}
					}


					a {
						display: block;
						padding: 3px 0;
						color: #797687;
						font-size: 14px;
						font-weight: 500;

						&:after {
							display: none;
						}

						&:hover,
						&.current_page {
							color: $color__theme
						}
					}


					&:last-child {
						a {
							border-bottom: 0;
						}
					}
				}
			}

			&:hover {
				.sub-menu {
					opacity: 1;
					visibility: visible;
					top: 100%;
				}
			}
		}
	}

	.nav-dark {
		.site-main-menu {
			li {
				a {
					color: #2b2350;
					&:after {
						display: none;
					}
					&:hover,
					&.current_page {
						color: $color__theme;
					}
				}

				&.menu-item-has-children {
					&:after {
						color: #2b2350;
					}
				}
			}
		}
	}
}

.logo-sticky {
	display: none;
}

.site-header.pix-header-fixed {
	top: 0;
	left: 0;
	right: 0;
	position: fixed;
	z-index: 999999;
	box-shadow: 0px 10px 20px 0px rgba(79, 35, 35, 0.08);
	animation: stickySlideDown .65s cubic-bezier(.23, 1, .32, 1) both;
	background: #fff;

	.header-inner {
		.site-logo {
			padding: 10px 0;
			.logo-sticky {
				display: block;
			}
			.logo-main {
				display: none;
			}
		}

		.site-nav {
			.nav-right {
				.nav-btn {
					background: transparent;
					color: $color__theme;
					box-shadow: none;
					border-color: $color__theme;

					&:hover {
						background: $color__theme;
						color: #fff;

					}
				}
			}
		}
	}

	.site-main-menu {

		li {
			padding: 20px 0;

			&.menu-item-has-children:after {
				color: #333;
			}

			a {
				color: #333;
				&:after {
					display: none;
				}
				&:hover,
				&.current_page {
					color: $color__theme;

					&:before,
					&:after {
						background: $color__theme;
					}
				}
			}
		}
	}

	.site-logo {
		a {
			max-width: 120px;
		}

		.main-logo {
			display: none;
		}

		.sticky-logo {
			display: block;
		}

		@media (min-width: 768px) {
			max-width: 150px;
		}
	}
}