.banner-contact {
    overflow: hidden;
    padding: 100px 0 50px;

    .container {
        position: relative;
        z-index: 2;
    }

    .circle {
        position: absolute;
        top: -40%;
        left: -17%;
        display: none;
    }

    .page-title-wrapper {
        padding: 30px 0 0;
        position: relative;
        z-index: 2;

        .page-title {
            font-size: 32px;
            line-height: 48px;
        }

        p {
            font-size: 16px;
            line-height: 30px;
        }
    }

    img.splash {
        width: 100%;
    }

    .animate-ball {
        position: absolute;
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;
        top: 0;
        left: 0;
    
        .ball {
            background: rgb(255, 248, 248);
            border-radius: 50%;
            position: absolute;
    
            &:nth-child(1) {
                height: 50px;
                width: 50px;
                top: 37%;
                left: 10%;
                animation: zoommd 1.5s infinite alternate;
            }
    
            &:nth-child(2) {
                height: 70px;
                width: 70px;
                bottom: 22%;
                right: 8%;
                animation: bounce 4s infinite alternate;
            }
    
            &:nth-child(3) {
                bottom: 55%;
                right: 40%;
                height: 70px;
                width: 70px;
                animation: zoommd 1.5s infinite alternate;
            }
    
            &:nth-child(4) {
                height: 40px;
                width: 40px;
                bottom: 28%;
                right: 40%;
                animation: movexy 1.5s infinite alternate;
            }
    
            &:nth-child(5) {
                height: 500px;
                width: 500px;
                top: -102%;
                right: 0;
                animation: wave 3s 0.1s infinite linear;
            }
        }
    }

    @media (min-width: 576px) {
        .page-title-wrapper {
            padding: 0;
        }
    }

    @media (min-width: 768px) {
        padding: 100px 0;
        .circle {
            display: block;
        }
    }

    @media (min-width: 992px) {
        .page-title-wrapper {
            .page-title {
                font-size: 40px;
                line-height: 54px;
            }
        }
    }

    @media (min-width: 1200px) {
        .animate-ball {
            .ball {
                &:nth-child(5) {
                    top: -60%;
                    right: 100px;
                }
            }
        }
    }
}