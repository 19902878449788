.banner {
	position: relative;

	&.banner-video {
		.banner-content {
			position: absolute;
			left: 0;
			right: 0;
			margin-top: 100px;
			z-index: 1;
			@media(min-width: 720px) {
				margin-top: 150px;
			}
			@media(min-width: 1024px) {
				margin-top: 200px;
			}
		}

		.background {
			overflow: hidden;
			display: flex;
			align-items: center;
			min-height: 400px;
			max-height: 600px;

			video {
				width: 100%;
				display: none;
			}

			.filter {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				background: rgba(54, 8, 100, 0.75);
			}

			@media(min-width: 720px) {
				video {
					display: block;
				}
			}
		}

		strong {
			color: $color__theme;
			font-weight: 500;
		}
	}

	.banner-content-wrap {
		position: relative;
		transform: translateY(52%);
		z-index: 2;
	}

	.banner-content {
		.banner-title {
			font-size: 60px;
			line-height: 70px;
			font-weight: 400;
			color: #fff;
			margin-bottom: 20px;

			span {
				font-weight: 800;
			}
		}

		.description {
			font-size: 18px;
			font-weight: 400;
			color: #fff;
			line-height: 30px;
			margin-bottom: 47px;
		}

		.banner-btn {
			color: #fff;
			padding: 10px 40px;
			border: 1px solid #fff;
			border-radius: 30px;
			font-size: 14px;
			display: inline-block;

			&:hover {
				background: #fff;
				color: $color__theme;
				box-shadow: 0px 20px 30px 0px rgba(79, 35, 35, 0.15);
			}
		}
	}

	&.banner-three {
		background-image: $gradient_background;
		height: 800px;
		min-height: 700px;
		margin-bottom: 200px;

		.banner-content-wrap-two {
			padding-top: 185px;
		}

		.banner-content {
			margin-bottom: 100px;
			position: relative;
			z-index: 23;

			.banner-title {
				font-size: 50px;
				line-height: 60px;
				font-weight: 800;
				margin-bottom: 20px;
				span {
					&:before {
						content: ' ';
					}
				}
				@media (min-width: 1024px) {
					span {
						display: block;
						&:before {
							content: '';
						}
					}
				}
			}

			.description {
				font-size: 18px;
				font-weight: 400;
				color: #fff;
				line-height: 30px;
				margin-bottom: 47px;
			}

			.banner-btn {
				color: #fff;
				padding: 8px 47px;
				border: 1px solid #fff;
				border-radius: 30px;
				font-size: 14px;
				display: inline-block;
				font-weight: 600;

				&.btn-fill {
					color: $color__theme;
				}

				&:hover {
					background: #fff;
					color: $color__theme;
					box-shadow: 0px 20px 30px 0px rgba(79, 35, 35, 0.15);
				}
			}
		}

		.circle-shape,
		.shape {
			position: absolute;
			z-index: 0;
			top: 0;
		}

		.circle-shape {
			left: 0;
		}

		.shape {
			right: 0;
		}

		.promo-mockup {
			position: relative;
			display: flex;
			justify-content: center;
			z-index: 30;
			padding-bottom: 100px;

			.swiper-slide {
				width: auto;
				max-width: 94%;
				height: 400px;
				align-self: baseline;
			}

			@media (min-width: 576px) {
				.swiper-slide.low {
					top: 30px;
				}
			}

			img {
				box-shadow: 0px 20px 60px 0px rgba(79, 35, 35, 0.15);
				border-radius: 10px;
				max-height: 100%;
			}
		}

		.bg-shape-inner {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			overflow: hidden;
		}
	}
}


.animate-particle {
	position: absolute;
	top: 0;
	left: 0;
	margin: 0;
	padding: 0;
	list-style: none;
	height: 100%;
	width: 100%;
	z-index: 22;

	li {
		position: absolute;

		&:nth-child(1) {
			left: 90px;
			top: 140px;
			animation: animationFramesRight 40s infinite linear;
		}

		&:nth-child(2) {
			left: 40%;
			bottom: 13%;
			animation: animationFramesRight 30s infinite linear;
		}

		&:nth-child(3) {
			right: 28%;
			top: 14%;
			animation: animationFramesRight 30s infinite linear;
		}

		&:nth-child(4) {
			right: 5%;
			top: 63%;
			animation: animationFramesLeft 20s infinite linear;
		}

		&:nth-child(5) {
			left: 50%;
			top: 25%;
			animation: animationFramesLeft 20s infinite linear;
		}

		&:nth-child(6) {
			right: -9%;
			top: 110px;
		}

		&.bubble {
			height: 10px;
			width: 10px;
			background: #a2e3f6;
			border-radius: 50%;
			left: 15%;
			top: 28%;
			animation: animationFramesLeft 30s infinite linear;
		}
	}
}

.swiper-pagination {
	margin-top: 25px;
	position: absolute;
	left: 50% !important;
	transform: translateX(-50%);

	.swiper-pagination-bullet {
		height: 14px;
		width: 14px;
		margin-right: 3px;

		&:focus {
			outline: 0;
		}


		&.swiper-pagination-bullet-active {
			background: #fff;
			opacity: 1;
		}
	}
}

.scroll-circle {
	position: absolute;
	bottom: -48%;
	z-index: 1;
}

@media (max-width: 1600px) {
	.banner {
		&.banner-three {
			.circle-shape {
				left: -20%;
			}
		}

	}
}

@media (max-width: 1440px) {
	.banner {
		&.banner-three {
			.circle-shape {
				left: -35%;
			}

			.shape {
				right: -15%;
			}
		}
	}
}

@media (min-width: 992px) and (max-width: 1200px) {
	.banner {
		&.banner-three {
			.shape {
				right: -25%;
			}
		}
	}
}

@media (max-width: 991px) {
	.banner {
		&.banner-three {
			.promo-mockup {
				img {
					box-shadow: 0px 40px 50px 0px rgba(79, 35, 35, 0.2);
				}
			}

			.circle-shape {
				left: -50%;
			}

			.shape {
				right: -40%;
			}

			.banner-content {
				.banner-title {
					font-size: 45px;
					line-height: 55px;
				}
			}

		}
	}
}

@media (max-width: 768px) {
	.banner {
		&.banner-three {
			height: 750px;

			.banner-content {
				margin-bottom: 50px;

				.banner-title {
					font-size: 34px;
					line-height: 44px;
				}

				.description {
					br {
						display: none;
					}
				}
			}

			.banner-content-wrap-two {
				padding-top: 130px;
			}


			.circle-shape {
				left: -85% !important;
			}

			.shape {
				right: -60% !important;
			}

		}
	}
}

@media (max-width: 640px) {
	.banner {
		&.banner-three {
			.circle-shape {
				left: -95% !important;
			}

			.shape {
				right: -70% !important;
			}
		}
	}
}

@media (max-width: 576px) {
	.banner {
		.banner-content {
			.description {
				font-size: 16px;
			}
		}

		&.banner-three {
			.circle-shape {
				left: -130% !important;
			}
			.shape {
				right: -90% !important;
			}
		}
	}
}

@media (max-width: 480px) {
	.banner {
		&.banner-three {
			.banner-content {
				.banner-title {
					font-size: 30px;
					line-height: 40px;
				}
			}
		}
	}
}