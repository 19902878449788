@keyframes zoommd {
    0% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1.5);
    }
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(40px);
    }
}

@keyframes movexy {
    0% {
        -webkit-transform: translateX(-10px) scale(0.9);
        transform: translateX(-10px) scale(0.9);
    }

    100% {
        -webkit-transform: translateX(30px) scale(1.3) translateY(10px);
        transform: translateX(30px) scale(1.3) translateY(10px);
    }
}

@keyframes stickySlideDown {
    from {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }

    to {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes animationFramesLeft {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }

    20% {
        transform: translate(-73px, 1px) rotate(-36deg);
    }

    40% {
        transform: translate(-141px, -72px) rotate(-72deg);
    }

    60% {
        transform: translate(-83px, -122px) rotate(-108deg);
    }

    80% {
        transform: translate(40px, -72px) rotate(-144deg);
    }

    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}

@keyframes animationFramesRight {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }

    20% {
        transform: translate(73px, 1px) rotate(36deg);
    }

    40% {
        transform: translate(141px, 72px) rotate(72deg);
    }

    60% {
        transform: translate(83px, 122px) rotate(108deg);
    }

    80% {
        transform: translate(-40px, 72px) rotate(144deg);
    }

    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}

@keyframes wave {
    0% {
        transform: rotateZ(0deg) translate3d(0, 3%, 0) rotateZ(0deg);
    }

    100% {

        transform: rotateZ(360deg) translate3d(0, 3%, 0) rotateZ(-360deg);
    }
}