#review-slider-wrapper {
    position: relative;
    #review-sliders {
        overflow: visible;
        .swiper-slide {
            &.swiper-slide-visible { 
                .review-slider {
                    box-shadow: 0px 30px 60px 0px rgba(48, 11, 33, 0.08);
                    opacity: 1;
                }
            }
        }
    }

    .swiper-pagination  {
        bottom: -60px;
    }
}

.review-slider {
    border-radius: 6px;
    background: #fff;    
    text-align: center;
    padding: 20px;
    height: 100%;
    min-height: 280px;
    transition: all 0.3s ease-in-out;
    opacity: 0.6;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    .review-brand-logo {
        max-width: 200px;
        display: inline-block;
    }

    @media (min-width: 1200px) {
        padding: 40px;
    }
}

.swiper-pagination {
    margin-top: 35px;

    .swiper-pagination-bullet {
        height: 12px;
        width: 12px;
        transition: all 0.3s ease-in-out;
        margin-right: 20px;
        position: relative;
        opacity: 1;
        background: transparent;
        outline: 0;


        &:before {
            content: '';
            position: absolute;
            height: 12px;
            width: 12px;
            background: rgb(229, 214, 214);
            z-index: 2;
            top: 0;
            left: 0;
            border-radius: 50%;
            left: -2px;
            top: -2px;
            transition: all 0.3s ease-in-out;
            transform: scale(0.7)
        }

        &.swiper-pagination-bullet-active {
            opacity: 1;

            &:before {
                opacity: 1;
                transform: scale(1);
                background: $color__theme;
                box-shadow: 0px 10px 14px 0px rgba(79, 35, 35, 0.3);
            }

        }
    }
}