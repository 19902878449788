.performance {
    background: #faf9fd;
    padding: 120px 0 125px;
}

.gp-tab-contentens {
    .section-heading {
        margin-bottom: 50px;
    }
}

.tabs-nav {
    .tabs-nav-item {
        margin-bottom: 20px;
        border-radius: 4px;
        padding: 20px 20px 20px 30px;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0px 20px 40px 0px rgba(43, 35, 79, 0.08);
        border-left: 5px solid $color__theme-2;
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        .acc-btn {
            color: #322d49;
            font-size: 16px;
            font-weight: 500;
        }

        .content {
            display: none;
            padding-top: 14px;
            p {
                margin-bottom: 22px;
            }
        }


        &:last-child {
            margin-bottom: 0;
        }

        .tab-icon {
            margin-right: 20px;
        }

        .more-btn {
            font-size: 14px;
            color: #5e5b74;
            font-weight: 500;

            i {
                vertical-align: middle;
                margin-left: 5px;
            }

            &:hover {
                color: $color__theme-2;
            }
        }

        @media (min-width: 576px) {
            .tabs-nav-item {
                padding: 25px 20px 25px 50px;
                .acc-btn {
                    font-size: 20px;
                }
            }
        }
    }
}


.tabs-content {
    padding: 40px 0;
    list-style: none;
    z-index: 22;
    position: relative;

    .tabs-content-item {
        display: none;
        opacity: 0;

        &.active {
            opacity: 1;

            .image-bottom {
                transform: translateY(0);
                opacity: 1;
            }

            .image-mid {
                transform: translateY(0);
                opacity: 1;
                transition-delay: 0.2s;
            }

            .image-top {
                opacity: 1;
                transition-delay: 0.4s;
                transform: scale(1);
            }

        }
    }

    .tab-image {
        position: relative;
        height: 320px;

        >div {
            position: absolute;
            opacity: 0;
            transition: all 1s cubic-bezier(0.4, 0, 0, 1);
            box-shadow: 0px 20px 60px 0px rgba(79, 35, 35, 0.15);
            border-radius: 10px;
            overflow: hidden;
        }

        .image-mid {
            z-index: 2;
            bottom: 0;
            left: 0;
            max-width: 75%;
            transform: translateY(-20px);
        }

        .image-bottom {
            z-index: 1;
            top: 0;
            right: 0;
            max-width: 85%;
            transform: translateY(30px);
        }

        .image-top {
            z-index: 3;
            bottom: -50px;
            right: -50px;
            max-width: 50%;
            transform: scale(0.7);
        }
    }

    [data-content="mobile"] {
        margin-top: 30px;
        .image-mid {
            max-width: 30%;
        }
        .image-bottom {
            max-width: 33%;
        }
        .image-top {
            max-width: 40%;
            left: 30%;
            bottom: auto;
            top: -30px;
        }
    }

    @media (min-width: 576px) {
        .tab-image {
            height: 340px;
        }
    }

    @media (min-width: 768px) {
        padding: 40px;
        .tab-image {
            height: 405px;
        }
    }
    @media (min-width: 992px) {
        .tab-image {
            height: 300px;
        }
    }
    @media (min-width: 1200px) {
        .tab-image {
            height: 400px;
        }
        [data-content="mobile"] .tab-image {
            height: 420px;
        }
    }
}