section {
	position: relative;

	&.padded {
		padding: 80px 0;
	}

	&.padded-top {
		padding-top: 80px;
	}

	&.gray {
		background: #fcfafb;
	}

	&.no-overflow {
		overflow: hidden;
	}

	&.design {

		.container-wrap {
			padding: 175px 0;
			background-color: #f6faf8;
	
			@media(min-width: 992px) {
				padding: 225px 0 175px;
			}
		}
	
	}

	@media (min-width: 992px) {

		&.padded {
			padding: 120px 0;
		}

		&.padded-top {
			padding-top: 120px;
		}

	}
}

#cloud {
	margin-bottom: -110px;
	z-index: 22;
}