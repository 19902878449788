.f-btn {
	padding: 8px 30px;
	background: $color__theme;
	border-radius: 30px;
	display: inline-block;
	font-size: 14px;
	color: #fff;
	font-weight: 600;
	box-shadow: 0px 20px 30px 0px rgba(176, 70, 70, 0.3);

	&.submit-btn {
		border: 0;
		padding: 11px 35px;
		font-size: 16px;

		&:focus {
			outline: 0;
		}
	}

	&.btn-large {
		padding: 16px 44px;
		font-size: 16px;
	}

	&.plus-icon {
		padding: 8px 64px 8px 41px;
		position: relative;

		i {
			margin-left: 10px;
			font-size: 20px;
			display: inline-block;
			position: absolute;
			right: 32px;
			top: 11px;
		}
	}

	&.btn-big {
		padding: 16px 47px;
		font-size: 16px;
	}

	&.btn-grey {
		background: #f3f3f4;
		color: #322d49;
		box-shadow: none;
		border-radius: 4px;
		padding: 11px 40px;

		&:hover {
			background: $color__theme-5;
		}

		&.color-three {
			&:hover {
				background: $color__theme-6;
				box-shadow: 0px 10px 20px 0px rgba(11, 176, 112, 0.3);
			}
		}
	}

	&:hover,
	&:focus {
		color: #fff;
		box-shadow: none;
		outline: 0;
	}

	&.btn-outline {
		border: 1px solid $color__theme;
		background: transparent;
		color: $color__theme;
		box-shadow: none;

		&:hover {
			background: $color__theme;
			color: #fff;
			box-shadow: 0px 20px 30px 0px rgba(176, 70, 70, 0.3);
		}

	}

	&.btn-outline-two {
		border: 1px solid $color__theme-2;
		background: transparent;
		color: $color__theme-2;
		box-shadow: none;

		&:hover {
			background: $color__theme-2;
			color: #fff;
			box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
		}
	}

	&.btn-light {
		background: #fff;
		color: $color__theme;
		border: 2px solid #fff;
		padding: 14px 40px;

		&:focus {
			box-shadow: none;
			outline: 0;
		}

		&:hover {
			background: transparent;
			color: #fff;
			border-color: #fff;
		}

		&.btn-outline {
			background: transparent;
			color: #fff;

			&:hover {
				color: $color__theme-4;
				background: #fff;

			}
		}
	}

	&.btn-three {
		background: $color__theme-5;
		border: 1px solid $color__theme-5;
		box-shadow: none;

		&:hover {
			background: transparent;
			color: $color__theme-5
		}
	}

	&.btn-round {
		border-radius: 4px;
	}


	&.btn-four {
		background: $color__theme-6;
		border: 1px solid $color__theme-6;
		box-shadow: none;

		&:hover {
			background: transparent;
			color: $color__theme-6
		}
	}

	&.btn-round {
		border-radius: 4px;
	}
}