.cta {
    background-image: $gradient_background;
    padding: 111px 0 120px;
    overflow: hidden;

    .overlay-bg {
        position: absolute;
        right: 0;
        top: 0;
    }

    .cta-content {

        .title {
            color: #fff;
            font-size: 32px;
            line-height: 42px;
            font-weight: 700;
            margin-bottom: 28px;
        }

        p {
            color: #fff;
            margin-bottom: 50px;
            font-size: 18px;
        }

        .f-btn {
            padding: 12px 50px;
            font-size: 16px;
            font-weight: 500;
            position: relative;
            z-index: 2;
        }
    }

    .scroll-circle {
        right: 0;
        top: 40%;
        max-width: 300px;
    }

    @media (min-width: 768px) {
        padding: 71px 0 80px;
        .cta-content {
            .title {
                font-size: 40px;
                line-height: 54px;
            }
        }
        .scroll-circle {
            max-width: 400px;
        }
    }

    @media (min-width: 992px) {
        .cta-content {
            .title {
                font-size: 50px;
                line-height: 64px;
            }
        }
        .scroll-circle {
            max-width: none;
        }
    }
}